<template>
    <div class="d-flex list-container">
        <!-- top start -->
        <div class="top d-flex">
            <el-select v-model="currentProjectName" @change="changeProject" placeholder="请选择">
                <el-option v-for="(item, index) in currentProjectList" :key="item.id" :value="index"
                    :label="item.projectName"></el-option>
            </el-select>
        </div>
        <!-- top end -->
        <!-- bottom start -->
        <div class="bottom d-flex">
            <div class="left d-flex">
                <div class="bottom-title">待确认审查意见</div>
                <div class="tabs-box d-flex">
                    <div @click="changeTab(index)" :class="index === currentTabIndex ? 'active tab' : 'tab'"
                        v-for="(tab, index) in tabsList" :key="index">
                        <div>{{ tab.userName }}</div>
                        <div v-if="index !== 0">
                            {{ tab.molecule }}/{{ tab.denominator }}
                        </div>
                    </div>
                </div>
                <div v-if="currentTabIndex == 0" class="check-group d-flex">
                    <el-radio v-model="status" label="1">2人以上审查出来的意见</el-radio>
                    <el-radio v-model="status" label="2">2人（含）以下审查出来的意见</el-radio>
                </div>
                <div class="unConfirmedList-box">
                    <el-card v-for="(item, index) in unConfirmedList" :key="index">
                        <el-tag v-if="currentTabIndex == 0" type="danger">{{ '提出人：' + item.userNames }}</el-tag>
                        <div>位置：图纸位置：{{ formatPath(item.drProjectFilePath) }}{{item.opinionType===124?item.drawingName:''}}{{item.opinionType===126?item.locationDescription:''}}<el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary" @click="checkScreenshots(item.positionImg)">查看截图</el-link></div>
                        <div>审查内容：</div>
                        <div>
                            {{ item.confirmProblem }}
                        </div>
                        <div>问题属性：{{item.ddIdQuestionPropertieValue}}</div>
                        <div>问题分类：{{item.ddIdQuestionTypeValue}}</div>
                        <el-button :disabled="item.isConfirmed" v-if="currentTabIndex == 0" @click="addProblems(item)" size="mini" type="primary">{{item.isConfirmed?'已添加':'添加'}}</el-button>
                    </el-card>
                </div>
            </div>
            <div class="right d-flex">
                <div class="bottom-title d-flex">
                    <span>已确认审查意见</span>
                    <el-button @click="addSuggest" size="mini" type="primary">去添加图审意见</el-button>
                </div>
                <div class="rt-bottom d-flex">
                    <div class="confirmedList-box">
                        <!-- <el-card v-for="(item, index) in confirmedList" :key="index">
                            <div>位置：图纸位置：{{ formatPath(item.drProjectFilePath) }}{{item.opinionType===124?item.drawingName:''}}{{item.opinionType===126?item.locationDescription:''}}<el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary" @click="checkScreenshots(item.positionImg)">查看截图</el-link></div>
                            <div>审查内容：</div>
                            <div>
                                {{ item.confirmProblem }}
                            </div>
                            <div>问题属性：{{item.ddIdQuestionPropertieValue}}</div>
                            <div>问题分类：{{item.ddIdQuestionTypeValue}}</div>
                            <div v-if="item.isEdit" class="d-flex">
                                <div class="w-55">问题：</div><el-input v-model="item.confirmProblemEdit" size="small" />
                            </div>
                            <div class="mt-20" v-if="!item.isEdit">
                                <el-button @click="updateProblems(item)" size="mini" type="primary">编辑</el-button>
                                <el-button @click="delProblems(item)" size="mini" type="danger">删除</el-button>
                            </div>
                            <div class="mt-20" v-if="item.isEdit">
                                <el-button @click="confirmUpdateProblems(item)" size="mini" type="primary">确认</el-button>
                                <el-button @click="cancelUpdateProblems(item)" size="mini">取消</el-button>
                            </div>
                        </el-card> -->
                        <div v-for="(item, index) in confirmedList" :key="index">
                            <div class="rightTitle">{{item.questionTypeName}}</div>
                            <div v-for="(o, i) in item.opinionSummaryConfirmedList" :key="i">
                                <div class="rightLoca">位置:{{o.drProjectFilePath + o.drawingName}}</div>
                                <div class="rightContent">{{(i + 1) + '、' + o.confirmProblem + '，' + o.drStandardItemDescribe + '（' + o.ddIdQuestionPropertieValue + '）'}}</div>
                                <div v-if="o.isEdit" class="d-flex">
                                    <div class="w-55">问题：</div><el-input v-model="o.confirmProblemEdit" size="small" />
                                </div>
                                <div class="mt-20" v-if="!o.isEdit">
                                <el-button @click="updateProblems(o)" size="mini" type="primary">编辑</el-button>
                                <el-button @click="delProblems(o)" size="mini" type="danger">删除</el-button>
                            </div>
                                <div class="mt-20" v-if="o.isEdit">
                                    <el-button @click="confirmUpdateProblems(o)" :loading="confirmLoading" size="mini" type="primary">确认</el-button>
                                    <el-button @click="delProblems(o)" size="mini" type="danger">删除</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-button @click="createReport" style="" size="mini" type="primary">生成技术部复核意见</el-button>
                </div>
            </div>
        </div>
        <!-- bottom end -->
        <el-dialog
        title="确认删除"
        :visible.sync="handleDeleteVisible"
        v-model="handleDeleteVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item>
                    确认删除该意见吗？
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleDeleteVisible = false">取消</el-button>
                <el-button @click.native="confirmDelete" type="primary">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog
        title="生成报告"
        :visible.sync="handleGenerateVisible"
        v-model="handleGenerateVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item>
                    生成报告后将无法修改，确认生成报告吗？
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleGenerateVisible = false">取消</el-button>
                <el-button @click.native="confirmGenerate" :loading="confirmGenerateStatus" type="primary">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getUngatherProjectList, getPeopleCount, getDrOpinionSummaryUnconfirmed, getDrOpinionSummaryConfirmed, updateConfirmedOpinion, deleteConfirmedOpinion, addOpinionConfirmed, generatePreliminaryReviewReport, getDrOpinionSummaryUnconfirmedForUser } from "@/api/api";
import { createLogger } from "vuex";
export default {
    components: { Toolbar },
    data() {
        return {
            currentProject: undefined,
            currentProjectName: '',
            currentTab: {},
            currentItem: {},
            currentTabIndex: 0,
            handleDeleteVisible: false,
            handleGenerateVisible: false,
            confirmGenerateStatus: false,
            currentProjectList: [],
            auditList: [],
            tabsList: [
                {
                    userName: '自动汇总'
                }
            ],
            status: '1',
            unConfirmedList: [],
            confirmedList: [],
            showScreenshotVisible: false,
            positionImg:'',
            confirmLoading: false,
        };
    },
    watch: {
        'currentProject': function(){
            if(this.currentProject){
                this.getTabsList();
                this.getUnConfirmedList();
                this.getConfirmedList();
            }
        },
        'status': function(){
            this.getUnConfirmedList();
        },
        'currentTab': function(){
            this.getUnConfirmedList();
        },
    },
    created() {
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getCurrentProjectList();
    },
    methods: {
        // 获取当前在审项目
        getCurrentProjectList(){
            getUngatherProjectList({}).then(res => {
                var result = res.data;
                if(result.success) {
                    this.currentProjectList = result.response;
                    if(result.response && result.response.length > 0){
                        this.currentProject = result.response[0];
                        this.currentProjectName = result.response[0].projectName;
                    }else{
                        this.currentProject = {};
                        this.tabsList = [
                            {
                                userName: '自动汇总'
                            }
                        ];
                        this.unConfirmedList = [];
                        this.confirmedList = [];
                    }
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        // 获取汇总总数及tabs
        getTabsList() {
            if(!this.currentProject || !this.currentProject.projectId){
                return;
            }
            var params = {
                projectId: this.currentProject.projectId,
            }
            getPeopleCount(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.tabsList = [this.tabsList[0], ...result.response]
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        // 获取未确认列表
        getUnConfirmedList() {
            if(!this.currentProject || !this.currentProject.projectId){
                return;
            }
            var params = {
                projectId: this.currentProject.projectId,
            }
            if(this.currentTab && this.currentTab.userId){
                params.userId = this.currentTab.userId;
                getDrOpinionSummaryUnconfirmedForUser(params).then(res => {
                    var result = res.data
                    if(result.success){
                        this.unConfirmedList = result.response;
                    }else{
                        this.$message.error(result.message);
                    }
                })
            }else{
                if(this.status == "1"){
                    params.flagGreater2 = true;
                }else if(this.status == "2"){
                    params.flagLess2 = true;
                }
                getDrOpinionSummaryUnconfirmed(params).then(res => {
                    var result = res.data
                    if(result.success){
                        this.unConfirmedList = result.response;
                    }else{
                        this.$message.error(result.message);
                    }
                })
            }
        },
        // 获取已确认列表
        getConfirmedList() {
            if(!this.currentProject || !this.currentProject.projectId){
                return;
            }
            var params = {
                projectId: this.currentProject.projectId,
            }
            getDrOpinionSummaryConfirmed(params).then(res => {
                var result = res.data
                if(result.success){
                    this.confirmedList = result.response;
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        // 添加问题
        addProblems(item) {
            addOpinionConfirmed({id: item.id}).then((res) => {
                var result = res.data
                if(result.success){
                    this.getConfirmedList();
                    this.getUnConfirmedList();
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        // 删除问题
        delProblems(item) {
            this.handleDeleteVisible = true;
            this.currentItem = item;
        },
        confirmDelete() {
            deleteConfirmedOpinion({id: this.currentItem.id}).then((res) => {
                this.handleDeleteVisible = false;
                var result = res.data
                if(result.success){
                    this.getConfirmedList();
                    this.getUnConfirmedList();
                }else{
                    this.$message.error(result.message);
                }
            });
        },
        // 编辑问题
        updateProblems(item) {
            // item.isEdit = true;
            // item.confirmProblemEdit = item.confirmProblem;
            this.$set(item, "isEdit", true);
            this.$set(item, "confirmProblemEdit", item.confirmProblem);
        },
        confirmUpdateProblems(item) {
            item.confirmProblem = item.confirmProblemEdit;
            var params = item;
            this.confirmLoading = true
            updateConfirmedOpinion(params).then((res) => {
                let result = res.data;
                if(result.success){
                    item.isEdit = false;
                }else{
                    this.$message.error(result.message);
                }
                this.confirmLoading = false
            })
        },
        cancelUpdateProblems(item) {
            this.$set(item, "isEdit", false);
        },
        // 添加意见
        addSuggest() { },
        // 选择项目
        changeProject(val){
            this.currentProject = this.currentProjectList[val];
        },
        // 生成技术部复核意见
        createReport() {
            this.handleGenerateVisible = true;
            this.confirmGenerateStatus = false;
        },
        confirmGenerate() {
            this.confirmGenerateStatus = true;
            generatePreliminaryReviewReport({projectId: this.currentProject.projectId}).then((res) => {
                this.confirmGenerateStatus = false;
                var result = res.data
                if(result.success){
                    this.handleGenerateVisible = false;
                    this.$message.success("报告生成成功");
                    this.getCurrentProjectList();
                }else{
                    this.$message.error("报告生成失败");
                    this.handleGenerateVisible = false;
                }
            });
        },
        // 切换tabs
        changeTab(index) {
            this.currentTabIndex = index;
            this.currentTab = this.tabsList[index];
        },
        formatPath(path) {
            if(path) {
                return path.replace(/(.*?)\/(.*?)\/(.*?)\//, '');
            }
        },
        // 查看截图
        checkScreenshots(positionImg) {
            this.showScreenshotVisible = true;
            this.positionImg = positionImg;
        },
    },
};
</script>

<style lang="stylus" scoped>
.d-flex {
  display: flex;
}

.mt-20 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.w-55 {
    width: 55px
}

.list-container {
  flex-direction: column;
    height: 100%;
  .top {
    height: 40px;
    padding-bottom: 10px;
    padding-left:10px;
    border-bottom:1px solid #fafafa; 
  }
  .bottom{
    height: 100%;
    padding-bottom:20px;
    .bottom-title{
        font-weight:bold;
        font-size:16px;
        justify-content: space-between;
    }
    .left{
        width:50%;
        background: #FAFAFA;
        flex-direction: column;
        padding:10px 20px;
        overflow-y: scroll;

        .tabs-box{
            margin-top:20px;
            .tab{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #909399;
                height: 50px;
                margin-right:10px;
                padding: 0 20px;
                border-radius: 16px; 
                color: #fff;
                font-size:12px;
                cursor:pointer;
            }
            .active{
                background: #409EFF;
            }
        }

        .check-group{
            margin-top:20px;
            height: 25px;
            line-height: 25px;
        }
        .unConfirmedList-box{
            margin-top:20px;
            
            >>>.el-card{
                margin-bottom:20px;
                .el-card__body{
                    div{
                        word-break: break-all;
                    }
                }
            }
        }
    }
    .right{
        width:50%;
        padding:10px 20px;
        overflow-y: scroll;
        flex-direction: column;
        .rt-bottom{
            height: 93%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .confirmedList-box{
            margin-top:20px;
            width:100%;
            >>>.el-card{
                width:100%;
                margin-bottom:20px;
                .el-card__body{
                    div{
                        word-break: break-all;
                    }
                }
            }
        }
        }
    }
  }
}
.rightTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}
.rightLoca {
    font-size: 14px;
    color: #409EFF;
    margin: 5px 0;
}
.rightContent {
    font-size: 14px;
    margin: 5px 0;
}
</style>